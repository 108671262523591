import { render, staticRenderFns } from "./position.vue?vue&type=template&id=76dff037&scoped=true&"
import script from "./position.vue?vue&type=script&lang=js&"
export * from "./position.vue?vue&type=script&lang=js&"
import style0 from "./position.vue?vue&type=style&index=0&id=76dff037&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76dff037",
  null
  
)

export default component.exports